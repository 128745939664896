<style scoped>
    .elevation-2 {
        border-radius: 0;
    }
    .expand-area {
        padding: 20px !important;
        background: #eee;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="sites"
                        item-key="Id"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/integrate/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field> -->
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.Is_production="{ item }">
                                {{ item.Is_production == 0 ? "Test" : "Production" }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon small @click="open_update(item)" >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            
                        </v-data-table>
                    </div>

                    <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Sửa</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-select
                                                                            v-model="edit_is_production"
                                                                            :items="modes"
                                                                            label="Mode"
                                                                            item-text="Name"
                                                                            item-value="Id"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field
                                                                            v-model="edit_name"
                                                                            label="Tên"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>

                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field
                                                                            v-model="edit_site"
                                                                            label="Website"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>*Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="update_partner()">Sửa</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

td {
    max-width: 400px;
    word-break: break-all;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Tích hợp'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: '',
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Tên", value: 'Name', sortable: false },
                { text: "Website", value: 'Site', sortable: false },
                { text: "Private Key", value: 'Private_key', sortable: false },
                { text: "Mode", value: 'Is_production', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],
            expanded: [],
            dialog: false,

            modes: [
                {Id: 0, Name: 'Test Mode'},
                {Id: 1, Name: 'Production Mode'},
            ],
            edit_id: '',
            edit_name: '',
            edit_site: '',
            edit_is_production: false,
        };
    },
    watch: {
        
    },
    computed: {
        sites() {
            return this.$store.getters['integrate/get_all_sites'];
        },
    },
    components: {
        
    },
    methods: {
        update_partner() {
            var self = this;
            var payload = {
                Id: this.edit_id,
                Name: this.edit_name,
                Site: this.edit_site,
                Is_production: this.edit_is_production,
                toast: this.$root.$bvToast
            };
            this.$store.dispatch('integrate/update_partner', payload).then(response => {
                if(response.data.Message == 'Success') {
                    self.dialog = false;
                    self.$store.dispatch('integrate/get_all', { toast: this.$root.$bvToast });
                }
            }).catch(() => { self.dialog = false; });
        },
        open_update(item) {
            this.edit_id = item.Id;
            this.edit_name = item.Name;
            this.edit_site = item.Site;
            this.edit_is_production = item.Is_production;

            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Nhóm sản phẩm", route: "index" }, { title: "Tất cả" } ]);
        // this.get_categories();
        this.$store.dispatch('integrate/get_all', { toast: this.$root.$bvToast })
    }
};
</script>
